<template>
    <div class="comp">
        <CompForm ref="comp_form" :title="orgName" :submitBefore="onSubmitBefore" :submitApi="submit_api" :dataWatch="watchForm" @on-submit="onSubmitForm">
            <template v-slot:form-box="data">
                <ElemFormItem column title="体系架构名称" name="rootOrgName" required>
                    <Input style="width:400px" v-model.trim="data.form.rootOrgName" placeholder="请输入架构名称"></Input>
                </ElemFormItem>
                <ElemFormItem column title="体系架构模版" name="orgType" required>
                    <Select v-model="data.form.orgType" style="width:400px">
                        <Option v-for="(item, idx) in templateList" :key="idx" :value="item.orgType" :disabled="excludeOrgTypes.has(item.orgType)">{{ item.rootOrgName }}</Option>
                        <!-- <Option value="0">自定义</Option> -->
                    </Select>
                </ElemFormItem>

                <block v-if="category === 'personnel'">
                    <block v-for="(item, idx) in data.form.depthJson" :key="idx">
                        <ElemFormItem column :title="item.title">
                            <Input style="width:400px" v-model.trim="item.rootOrgName" placeholder="请填写层级名称"></Input>
                        </ElemFormItem>
                        <ElemFormItem v-if="idx < data.form.depthJson.length - 1" column title="负责岗位">
                            <CheckboxGroup v-model="item.postIdList" style="width:400px">
                                <Checkbox v-for="(item, idx) in postList" :key="idx" :label="item.postId" style="margin: 3px">{{ item.postName }}</Checkbox>
                            </CheckboxGroup>
                        </ElemFormItem>
                    </block>
                </block>

                <block v-else>
                    <ElemFormItem column title="第一层级" name="firstRootOrgName">
                        <Input style="width:400px" v-model.trim="data.form.firstRootOrgName" placeholder="请填写第一层级名称"></Input>
                    </ElemFormItem>
                    <ElemFormItem column title="负责岗位" name="firstPostId">
                        <CheckboxGroup v-model="data.form.firstPostId" style="width:400px">
                            <Checkbox v-for="(item, idx) in postList" :key="idx" :label="item.postId" style="margin: 3px">{{ item.postName }}</Checkbox>
                        </CheckboxGroup>
                    </ElemFormItem>
                    <ElemFormItem column title="第二层级" name="secondRootOrgName">
                        <Input style="width:400px" v-model.trim="data.form.secondRootOrgName" placeholder="请填写第二层级名称"></Input>
                    </ElemFormItem>
                    <ElemFormItem column title="负责岗位" name="secondPostId">
                        <CheckboxGroup v-model="data.form.secondPostId" style="width:400px">
                            <Checkbox v-for="(item, idx) in postList" :key="idx" :label="item.postId" style="margin: 3px">{{ item.postName }}</Checkbox>
                        </CheckboxGroup>
                    </ElemFormItem>
                    <ElemFormItem column title="末尾层级">
                        <Input style="width:400px" v-model.trim="data.form.thirdRootOrgName" placeholder="请输入层级名称" disabled></Input>
                    </ElemFormItem>
                </block>

                <!-- <ElemFormList v-model="data.form.businessTimeRos">
                    <template v-slot:formBox="data">
                        <ElemFormItem :title="'第' + (data.index + 1) + '层级'">
                            <Input style="width:400px" v-model.trim="data.item.level" :placeholder="'请填写第' + (data.index + 1) + '层级名称'"></Input>
                        </ElemFormItem>

                        <ElemFormItem title="负责岗位">
                            <CheckboxGroup v-model="data.item.operating" style="width:400px">
                                <Checkbox v-for="(item, idx) in postList" :key="idx" :label="item.postId" border style="margin: 3px">{{ item.postName }}</Checkbox>
                            </CheckboxGroup>
                        </ElemFormItem>
                    </template>
                </ElemFormList> -->
            </template>
        </CompForm>
    </div>
</template>

<script>
import CompForm from "../../jointly/components/CompForm.vue"
import ElemFormItem from "../../jointly/components/elem-form-item.vue"
import UserInfo from "../entity/UserInfo"
// import ElemFormList from "../../jointly/components/elem-form-list.vue"

export default {
    components: {
        CompForm,
        ElemFormItem,
    },

    data() {
        return {
            orgName: "",
            postList: null,
            templateList: null,
            submit_api: `/gateway/org/pc/grassrootsOrg/${this.category === "personnel" ? "v2/" : ""}generateRootOrg`,
            // 监听表单字段变化
            watchForm: {
                orgType: (val, fd) => {
                    if (!val && val !== 0) return
                    for (let i = 0, ls = this.templateList; i < ls.length; i++) {
                        let v = ls[i]
                        if (v.orgType === val) {
                            if (this.category === "personnel") {
                                return this.$set(
                                    fd,
                                    "depthJson",
                                    v.config?.depthJson?.map(e => {
                                        e.title = e.rootOrgName
                                        return e
                                    })
                                )
                            }

                            this.$set(fd, "firstRootOrgName", v.config?.firstRootOrgName || v.config?.firstName || "")
                            this.$set(fd, "secondRootOrgName", v.config?.secondRootOrgName || v.config?.secondName || "")
                            this.$set(fd, "thirdRootOrgName", v.config?.thirdRootOrgName || v.config?.thirdName || "责任区")
                            break
                        }
                    }
                },
            },
        }
    },

    props: {
        excludeOrgTypes: {
            type: Set,
            default: () => new Set(),
        },
        category: String,
    },

    async created() {
        this.orgName = UserInfo.get("orgName")
    },

    methods: {
        async display() {
            if (!this.postList) await this.getPostList()
            if (!this.templateList) await this.getTemplateList()

            this.$refs.comp_form.open()
        },

        getTemplateList() {
            return this.$get("/gateway/org/pc/grassrootsOrg/selectTemplateList").then(res => {
                if (res.code === "200") {
                    this.templateList = res.dataList?.filter(v => v.status === "2" && v.rootOrgName && (this.category === "personnel" ? v.config?.rootType == 2 : v.config?.rootType != 2))
                }
            })
        },

        /**
         * 获取岗位列表
         */
        getPostList() {
            return this.$get("/gateway/org/pc/grassrootsPost/selectPostList").then(res => {
                if (res.code === "200") {
                    this.postList = res.dataList
                }
            })
        },

        onSubmitBefore(data) {
            data = JSON.parse(JSON.stringify(data))

            data.communityCode = UserInfo.getCommunityCode()

            const temp = this.templateList.find(c => c.orgType === data.orgType)

            if (!temp) {
                return false
            }

            let cfg = temp.config

            data.rootType = this.category === "personnel" ? "2" : "1"

            if (this.category === "personnel") {
                data.depth = data.depthJson.length
                data.subList = data.depthJson.map((v, i) => {
                    return {
                        index: i + 1,
                        rootOrgName: v.rootOrgName,
                        appId: v.appId,
                        postIdList: v.postIdList,
                    }
                })
                delete data.depthJson
            } else {
                data.firstRootOrgAppId = cfg.firstAppId //第一层appid
                data.secondRootOrgAppId = cfg.secondAppId //第二层appid
                data.thirdRootOrgAppId = cfg.thirdAppId //第三层appid
            }

            return data
        },

        onSubmitForm(evt) {
            this.$emit("on-submit", evt)
        },
    },
}
</script>

<style lang="less" scoped>
.comp {
    .form-box {
        padding: 10px;
    }
}
</style>
